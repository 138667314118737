@font-face {
  font-family: 'Roboto';
  src: url('//assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('//assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

$enable-gradients: true;

$h1-font-size: 33px;
$h2-font-size: 30px;
$h3-font-size: 14px;
$h4-font-size: 22px;
// $h5-font-size: 20px;
$h6-font-size: 14px;

$primary: #2b2c74;
$secondary: #f39b32;
$info: #3c66aa;

html, body {
  margin: 0;
  padding: 0;
  font-family: "Google Sans",Roboto,Arial,Helvetica,sans-serif;
  // font-size: 16px;
}

input {
  font-size: 24px;
  padding:10px;
}

button {
  font-size: 20px;
}

h1 {
  line-height: 1.17857;
  font-weight: 400;
}

h2 {
  font-size: 30px !important;
  line-height: 1.3 !important;
  font-weight: 400 !important;
}

h3 {
  font-size: 14px !important;
  line-height: 1.3 !important;
  font-weight: 400 !important;
  text-transform: uppercase;
}

p {
  line-height: 1.6;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  color: #5f6368;
}
  
.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header ul li {
  font-weight: 400;
  font-size: 14px;
}

.header a {
  color: #5f6368;
}

.header a:hover {
  color: #000000;
  text-decoration: none;
}

nav a {
  font-size: 16px;
}

#mobile-navigation {
  display: none;
  width: 80%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transition: 0.5s;
  overflow-x: auto;
}

#mobile-navigation ul {
  list-style: none;
}

#mobile-navigation ul li a {
  font-size: 22px;
  font-weight: 300;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

section a {
  text-decoration: underline;
}

.cursor {
  cursor: pointer;
}

.border-md-left {
  border: none !important;
}

footer a {
  font-weight: 300;
  font-size: 16px;
}

.lds-dual-ring {
  display: inline-block;
  width: 46px;
  height: 46px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid var(--primary);
  border-color: var(--primary) transparent var(--primary) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.material-icons.md-red { color: var(--danger); }
.material-icons.md-white { color: white; }

@import '~material-design-icons/iconfont/material-icons.css';
@import '~bootstrap/scss/bootstrap.scss';